import { Button } from "@mui/material"
import logo from '../logo.svg';
import urlFor from "../utils/ImgUrl"
import { Navigate } from "react-router-dom";

export default function SetUser({allPeople, setUserid}) {

    function loginUser(id) {
        setUserid(id); 
        localStorage.setItem("solsikkaUser", id); 
        console.log(id); 
        return <Navigate to={`/${id}`} />
    }

    return (
        <section id="solopage">
            <img src={logo} alt="" />
            <h2>Hvem bruker Solsikka-appen i dag?</h2>
            {allPeople?.map((p, i) => 
            <Button key={i} onClick={
                () => {loginUser(p._id)}
                } variant="contained" size="large">
                <img src={p.image ? urlFor(p.image).width(50).format("webp").url() : `https://placehold.jp/24/4B2303/ffffff/50x50.png?text=${p.name[0]}`} alt={p.name} /> 
                {p.name}
            </Button>)}
        </section>
    )
}
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DateConverter from "../utils/DateConverter";
import urlFor from '../utils/ImgUrl';

export default function PersonPost({postdata}) {
    return (
        postdata ? 
        <article className="personPost">
            <span className="date"><CalendarMonthIcon fontSize="small" /> <DateConverter date={postdata._createdAt} /></span>
            <p>{postdata.text}</p>
            <img src={urlFor(postdata.image).width(600).format("webp").url()} alt={postdata.text} />
        </article> :
        <article className="personPost">
            <p>Det er ingen bilder fra denne deltageren enda.</p>
        </article>
    )
}
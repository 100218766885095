import {createClient} from '@sanity/client'

export const client = createClient({
    projectId: 'ucqcxhqn',
    dataset: 'production',
    useCdn: true,
    apiVerision: '2023-03-04'
})

export const writeClient = createClient({
    projectId: 'ucqcxhqn',
    dataset: 'production',
    token: 'skq5L24cYKeXiJls6cAfmxM7S7aq32jsec5zpgSZJ4WC3Wt7ndGMmQOdpn6JE4WTwKuYous41ROZWtxrOrd1g19OpWmgsUmjRr15CgHijlyNB9bl86iaN42HYx3sTqE2jfMS1Yvx23IyE6ItQC1o9MylaKSVXW9JePMhhSwdDnWjS0i40Tpt',
    apiVerision: '2023-03-04'
})
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

export default function Info() {
    return (
        <section className="text-container">
        <h1>Informasjon og regler</h1>
        <p>I denne solsikke-konkurransen plantes solsikker av typen "Giganteus". Denne kan bli 300centimeter høy. I konkurransen er det høyden på solsikken som teller, ikke størrelsen på blomsten eller antall blader eller frø.</p>
        <h3>Frister for bilder:</h3>
        <ul>
            <li>Etter plantene har spiret, send bilde av valgt plante.</li>
            <li>Uke 25 (19.-25. Juni)</li>
            <li>Uke 30 (24.-30. Juli)</li>
            <li>Søndag 27. august: Bilde av hele planten med målestokk</li>
        </ul>
        <h3>Regler</h3>
        <ul>
            <li>Alle deltakere velger tre frø. Når frøene spirer og skal plantes ut, må deltageren velge èn av plantene.</li>
            <li><ReportProblemIcon /> Et bilde av planten som velges, og plasseringen av denne planten, må legges ut i Solsikka-appen!</li>
            <li>Dersom planten som er valgt blir ødelagt på naturlig vis, eksempelvis av skadedyr, kan deltageren velge en av de andre plantene. <ReportProblemIcon /> Et bilde av den nye planten som velges, og plasseringen av denne planten, må legges ut i Solsikka-appen!</li>
            <li>Dersom planten som er valgt blir ødelagt av deltageren selv, er denne deltageren ute av konkurransen.</li>
            <li><ReportProblemIcon /> For å vise framdriften i konkurransen, skal alle deltagere legge ut et bilde av planten sin i Solsikka-appen i uke 25 og uke 30.</li>
            <li>I uke 34, søndag 27. august, skal deltageren legge inn et bilde i Solsikka-appen av hele planten, inkludert målestokk som viser hvor høy den er. Skriv gjerne høyde i tekstfeltet i Solsikka-appen sammen med bildet.</li>
        </ul>
        </section>
        )
}
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import DateConverter from "../utils/DateConverter"
import urlFor from "../utils/ImgUrl"
import { fetchPost } from "../utils/sanityservices/postService"
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import Loader from "../utils/Loader"
import { Alert, Button, TextField } from "@mui/material"
import { writeClient } from "../utils/sanityClient"

export default function Photo({userid, userProfile}) {
    const {id} = useParams()
    const [post, setPost] = useState(null)
    const [insertState, setInsertState] = useState("waiting")

    async function getImage(id) {
        const data = await fetchPost(id)
        setPost(data[0])
    }

    useEffect(() => {
        getImage(id)
    }, [id, insertState])

    const navigate = useNavigate();

    function handleSubmit(event) {
        event.preventDefault()
        const comment = document.querySelector("#commentText").value
        const author = document.querySelector("#author").value
        const commentObject = {
            _type: "comments", 
            author: {_type: "reference", _ref: author}, 
            image: {_type: "reference", _ref: id}, 
            comment: comment
        }
        writeClient.create(commentObject).then((result) => setInsertState("success")).catch((err) => console.log("Insert comment failed: ", err.message))
    }

    if(!post) {return (
        <Loader />
    )} 

    console.log(post)

    const SuccessMessage = ({photoid, setInsertState}) => {
        console.log(photoid)
        setInsertState("waiting")
        setTimeout(() => {
            navigate(`/bilde/${photoid}`)
        }, 2000)

        return <Alert severity="success">Kommentaren din er lagret. Den vil snart bli synlig på bildesiden</Alert>
    }

    return (
        <section id="singlePhoto">
            <div className="meta">
                <img className="profilepic" src={post.profilepic ? urlFor(post.profilepic).width(50).format("webp").url() : `https://placehold.jp/24px/FFB001/4B2303/50x50.png?text=${post.author[0]}`} alt={post.author} />
                <span className="name"><strong>{post.author}</strong></span>
                <span className="date"><DateConverter date={post._createdAt} /></span>
                <span className="commentCount"><p><ModeCommentIcon fontSize="small" /> {
                    post?.commentcount === 0 ? "0 kommentarer" : post?.commentcount === 1 ? "1 kommentar" : `${post?.commentcount} kommentarer`
                }</p></span>
            </div>
            
            <p>{post?.text}</p>
            <img src={urlFor(post?.image).width(700).format("webp").url()} alt={post?.text} />
            <section className="comments">
                {post?.comments?.map((c, i) => 
                    <article className="comment" id={c._id} key={i}>
                        <div className="comment-meta">
                            <div className="author">
                                <img src={urlFor(c.commenterpic).width(50).format("webp").url()} alt={c.commenter} />
                                <p className="author">{c.commenter}</p>
                            </div>
                            <p className="date">{<DateConverter date={c._createdAt} />}</p>
                        </div>
                        <p>{c.comment}</p>
                    </article>)}
            </section>

            {insertState === "success" ? 
             <SuccessMessage photoid={post._id} setInsertState={setInsertState} />
                
             :
            <form onSubmit={handleSubmit}>
                <h3>Legg til kommentar</h3>
                <TextField
                id="commentText"
                label="Kommentar"
                multiline
                rows={4}
                defaultValue="" 
                fullWidth sx={{color: "#ffffff"}}
                />
                <input type="hidden" name="author" id="author" value={userid} />
                
                <Button type="submit" variant="contained">Lagre kommentar</Button>
            </form>
            }
        </section>
        )
}
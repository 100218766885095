import { client } from "../sanityClient"

export async function fetchAllPosts() {
    const data = await client.fetch(`*[_type == "posts"] | order(_createdAt desc) {_id, image, text, "comments": count(*[_type == "comments" && image._ref == ^._id]), "author": author->name, "profilepic": author->image, _createdAt}`)
    return data
}

export async function fetchPost(id) {
    const data = await client.fetch(`*[_type == "posts" && _id == $id] {
        _id, _createdAt, image, text, 
        "author": author->name, 
        "profilepic": author->image, 
        "commentcount": count(*[_type == "comments" && image._ref == $id]),
        "comments": *[_type == "comments" && image._ref == ^._id] | order(_createdAt) {
            comment, 
            "commenter": author->name, 
            "commenterpic": author->image, 
            _createdAt}
        }`, 
    {id})
    return data
}

export async function fetchPersonPosts(id) {
    const data = await client.fetch(`*[_type == "people" && _id == $id]{
        name, "image": image.asset->url, "photos": *[_type == "posts" && author._ref == ^._id] | order(_createdAt desc) {text, _createdAt, image}
    }`,{id})
    return data
}
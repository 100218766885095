import logo from '../logo.svg';
import { Button, Divider, Drawer, Menu, MenuItem } from '@mui/material';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import MenuIcon from "@mui/icons-material/Menu";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CollectionsIcon from '@mui/icons-material/Collections';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import urlFor from '../utils/ImgUrl';


export default function Layout({userid, allPeople, userProfile}) {
    //Nav statuses
    const [menuStatus, setMenuStatus] = useState(false)
    const [userMenuStatus, setUserMenuStatus] = useState(null)
    const open = Boolean(userMenuStatus);
    const handleUserMenuClick = (event) => {
    setUserMenuStatus(event.currentTarget);
    };
    const handleUserMenuClose = () => {
    setUserMenuStatus(null);
    };
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
        }

        setMenuStatus(open);
    };

    return (
        <><section>
            <header className="App-header">
                <Link to={"/"} id="logo"><img src={logo} alt="Solsikka" /></Link>
                <ul>
                    {userProfile?.name ? <><li>
                        <Button
                            id="basic-button"
                            aria-controls={open ? 'usermenu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleUserMenuClick}
                        >
                        <img className="navProfilepic" src={
                            userProfile.image ? urlFor(userProfile?.image).width(35).format("webp").url() : 
                            `https://placehold.jp/24px/FFB001/4B2303/35x35.png?text=${userProfile.name[0]}`} 
                            alt={userProfile?.name} 
                        />
                        </Button>
                    </li>
                    <Menu
                        id="usermenu"
                        anchorEl={userMenuStatus}
                        open={open}
                        onClose={handleUserMenuClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                        <Link to={`/${userid}`}><MenuItem onClick={handleUserMenuClose}><CollectionsIcon /> Mine bilder</MenuItem></Link>
                        <Link to="/logout"><MenuItem onClick={handleUserMenuClose}><SwitchAccountIcon /> Bytt bruker</MenuItem></Link>
                        <Link to="/logout"><MenuItem onClick={handleUserMenuClose}><MeetingRoomIcon /> Logg av</MenuItem></Link>
                    </Menu>
      </> : null}
                    <li><MenuIcon onClick={toggleDrawer(true)} /></li>
                </ul>

            </header>
            <Drawer
                anchor={"right"}
                open={menuStatus}
                onClose={toggleDrawer(false)}
            >
                <Button onClick={toggleDrawer(false)} startIcon={<CloseIcon />} variant="contained" size="small">Lukk menyen</Button>
                <ul>
                    <li onClick={toggleDrawer(false)}><Link to={"/"}><PhotoLibraryIcon fontSize="small" /> Bildegalleri</Link></li>
                    <li onClick={toggleDrawer(false)}><Link to={"info"}><InfoIcon fontSize="small" /> Info og regler</Link></li>
                    <li onClick={toggleDrawer(false)}><Link to={"tips"}><LocalFloristIcon fontSize="small" /> Tips og tricks</Link></li>
                    <li onClick={toggleDrawer(false)}><Link to={"add"}><AddAPhotoIcon /> Legg til bilde/innlegg</Link></li>
                </ul>
                <Divider />
                <h2>Se innlegg fra</h2>
                <ul>
                    {allPeople?.map((p, i) => <li key={i} onClick={toggleDrawer(false)}><Link to={p._id}>{p.name}</Link></li>)}
                </ul>
                <Divider />
                <Button onClick={toggleDrawer(false)} startIcon={<CloseIcon />} variant="contained" size="small">Lukk menyen</Button>
            </Drawer>

            <main>
                {<Outlet context={[allPeople, userid]} />}
            </main>
        </section>
        <footer>
            <Link to={"info"}><InfoIcon /> Regler</Link>
            <Link to={"tips"}><LocalFloristIcon /> Tips</Link>
            <Link to={"add"}><AddAPhotoIcon />Legg til bilde</Link>
        </footer></>
      );
}
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Loader from "../utils/Loader"
import { fetchPersonPosts } from "../utils/sanityservices/postService"
import PersonPost from "./PersonPost"

export default function Person() {
    const [personPosts, setPersonPosts] = useState(null)
    let {id} = useParams()

    async function getPosts(id) {
        const data = await fetchPersonPosts(id)
        setPersonPosts(data[0])
    } 
    useEffect(() => {
        getPosts(id)
    }, [id])

    if(!personPosts) {
        return <Loader />
    }

    return (
        <section className="flex-container">
        <h1>Viser alle bildene fra {personPosts?.name}</h1>
        {personPosts?.photos?.map((p, i) => <PersonPost key={i} postdata={p}/>)}
        </section>
    )
}
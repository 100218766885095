import { useEffect } from "react"
import logo from '../logo.svg';

export default function Logout({setUserid, setUserProfile}) {

    function logoff() {
        setUserid(null)
        setUserProfile(null)
        localStorage.setItem("solsikkaUser", null)
    }
    
    useEffect(() => {
        logoff()
    })
    
    return (
        <section id="solopage">
        <img src={logo} alt="" />
        <p>Logger deg av...</p>
      </section>
    )
}
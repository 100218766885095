import { client } from "../sanityClient"

export async function fetchAllPeople() {
    const data = await client.fetch(`*[_type == "people"] | order(name asc) {_id,name,image}`)
    return data
}

export async function fetchUser(id) {
    const data = await client.fetch(`*[_type == "people" && _id == $id]{_id, name, image}`, {id})
    return data
}
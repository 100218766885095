import { Alert, Button, Step, StepLabel, Stepper } from "@mui/material"
import { useState } from "react"
import { Link, Navigate } from "react-router-dom"
import urlFor from "../utils/ImgUrl"
import { writeClient } from "../utils/sanityClient"
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

export default function Add({userid, userProfile}) {
    const [step, setSteps] = useState(0)
    const [message, setMessage] = useState(null)
    const [upload, setUpload] = useState(null)
    const [newPost, setNewPost] = useState(null)
   
    function handleImageSubmit() {
        const fileinput = document.getElementById("userUpload").files
        if(fileinput.length === 0) {
            setMessage("noimage")
        } else {
            writeClient.assets.upload('image', fileinput[0], {
                contentType: fileinput[0].type
            }).then((document) => {
                setUpload(document)
                const post = {
                    _type: "posts",
                    image: {
                      _type: "image",
                      asset: {
                        _type: "reference",
                        _ref: document._id,
                      },
                    },
                    author: {
                        _type: "reference",
                        _ref: userid
                    }
                }
                writeClient.create(post).then((postDocument) => {
                    setNewPost(postDocument._id)
                    console.log(postDocument)
                    setSteps((prevStep) => prevStep + 1)
                }).catch((error) => {
                    setMessage("postfail")
                    console.log('Creating post failed:', error.message);
                })
            })
            .catch((error) => {
                console.log('Upload failed:', error.message);
            })
        }
    }

    function handleTextSubmit() {
        const caption = document.getElementById("imageText").value
        caption ? 
            writeClient.patch(newPost).set({text: caption}).commit().then((updatedPost) => {setMessage("success"); setSteps((prevStep) => prevStep + 1);}).catch((err) => console.error("Oppdatering feilet: ", err.message))
            : setSteps((prevStep) => prevStep + 1); setMessage("success");

    }

    const stepLabels = ["Bilde", "Tekst", "Ferdig"]
    
    return (
        <section id="add">
            <h1>Legg til nytt bilde</h1>
            <Stepper activeStep={step}>
                {stepLabels.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>)
                )}
            </Stepper>
            {message === "success" ? (<><h2>Alt klart!</h2><p>Bildet du har lastet opp vil straks synes på <Link to="/">forsiden</Link> og på <Link to={`/${userid}`}>deltagerens innleggsside</Link>!</p><p>Du kan også <Link to="/add">legge til et nytt bilde</Link></p></>) 
            : !userid ? <Navigate to="/" />
                : !upload ? (
                    <form id="addImageForm">
                        <Button startIcon={<AddAPhotoIcon />} variant="contained" size="large" component="label">
                            Ta / Last opp bilde
                            <input hidden type="file" capture="environment" name="userUpload" id="userUpload" accept="image/*" onChange={handleImageSubmit} />
                        </Button>
                    </form>
                ) : !newPost ?
                    <Alert severity="info" style={{marginTop: "1rem", marginBottom: "1rem"}}>Systemet gjør klar opplastingen din... Dersom denne meldingen blir stående lenge, si fra til Marius (m@akerbak.com).</Alert>
                    : (
                        <form id="addTextForm">
                            <img className="uploadedImage" src={urlFor(upload).width(400).format("webp").url()} alt={document._id} />
                            <p>Vil du legge til en liten tekst som følger bildet (helt frivillig)?</p><p>Uansett om du skriver tekst eller ikke, klikk Lagre-knappen i bunnen når du er ferdig!</p>
                            <textarea name="imageText" id="imageText"></textarea>
                            <Button variant="contained" color="primary" onClick={handleTextSubmit}>Lagre</Button>
                        </form>
                    )
            }
        </section>
    )
}
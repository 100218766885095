import './App.scss';
import {Routes, Route, useLocation, useNavigate} from 'react-router-dom'
import Layout from './components/Layout';
import Frontpage from './components/Frontpage';
import Person from './components/Person';
import Add from './components/Add';
import 'dayjs/locale/nb'
import Info from './components/Info';
import Tips from './components/Tips';
import Photo from './components/Photo';
import { useEffect, useState } from 'react';
import { fetchAllPeople, fetchUser } from './utils/sanityservices/peopleService';
import Logout from './components/Logout';
import SetUser from './components/SetUser';
import logo from './logo.svg';



function App() {
  //Logged in user functions
  /*function loginUser(id) {
    console.log("Running loginUser with id ", id)
    localStorage.setItem("solsikkaUser", id)
    setUserid(id)
    savedUser()
    console.log(userid)
  }*/
  
  const savedUser = () => {
    const storedUser = localStorage.getItem("solsikkaUser")
    return storedUser !== null ? storedUser : null
  }
  const [userid, setUserid] = useState(savedUser)
  const [userProfile, setUserProfile] = useState(null)

  const getUser = async (id) => {
    const data = await fetchUser(id)
    setUserProfile(data[0])
  }

  //Prepare people list
  const [allPeople, setAllPeople] = useState(null)
  const getPeople = async () => {
      const data = await fetchAllPeople()
      setAllPeople(data)
  }

  const location = useLocation();
  const navigate = useNavigate();
  

  useEffect(() => {
      getPeople()
      getUser(userid)
  }, [userid])

  if(!userid || !userProfile) {
    if(location.pathname === "/logout") {
      <section id="solopage">
        <img src={logo} alt="" />
        <p>Logger deg av...</p>
      </section>
      
      setTimeout(() => {
        navigate("/")
      }, 1500)
    } else {
      return <SetUser allPeople={allPeople} setUserid={setUserid} />
    }
  } else {
    return(
      <Routes>
        <Route element={<Layout allPeople={allPeople} userid={userid} userProfile={userProfile} />}>
          <Route index element={<Frontpage />} />
          <Route path=":id" element={<Person />} />
          <Route path="info" element={<Info />} />
          <Route path="tips" element={<Tips />} />
          <Route path="add" element={<Add userid={userid} userProfile={userProfile} />}  />
          <Route path="bilde/:id" element={<Photo userid={userid} userProfile={userProfile} />} />
          <Route path="logout" element={<Logout setUserProfile={setUserProfile} setUserid={setUserid} />} />
        </Route>
      </Routes>
    )
  }
  
}

export default App;

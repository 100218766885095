import { createTheme } from '@mui/material/styles';

export const solsikka = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#FCCE09',
    },
    secondary: {
      main: '#FFB001',
      light: '#FCCE09'
    },
  },
  
});
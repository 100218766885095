import { Link } from "react-router-dom";

export default function Tips() {
    return (
        <section className="text-container">
            <h1>Tips & tricks</h1>
            <ul>
                <li>Så de tre valgte frøene i hver sin potte, ca. 1 centimeter ned i jorden.</li>
                <li>Sett pottene innendørs i 20-25&deg;C for spiring.</li>
                <li>Når frøene spirer (etter 5-15 dager), plasser dem lyst og svalt.</li>
                <li>Velg èn av plantene (se <Link to="/info">informasjon og regler</Link>). Plant den ut eller pott den om (større potte)</li>
                <li>OBS: Vent med å plante den valgte solsikken ut til faren for frost er over!</li>
                <li>Frøet kan også såes direkte på friland i Mai-Juni.</li>
                <li>Solsikker liker full sol (så det kan være lurt å plante den i Sør-Spania...)</li>
                <li>Solsikken blomstrer innenfor Juli-September.</li>
                <li>Bør plantes i dyp jord (kraftig vekst) og trenger masse vann.</li>
                <li>Kan med fordel gjødsles et par ganger i løpet av sommeren.</li>
                <li>Bind opp ved behov. Med tunge blomsterkroner kan det være lurt å "hjelpe" stilken. Knekte solsikker som ikke er bundet opp teller som "egen skade", og sender deltageren ut av konkurransen. (se <Link to="/info">informasjon og regler</Link>)</li>
            </ul>
        </section>
    )
}
import { Link } from 'react-router-dom';
import DateConverter from "../utils/DateConverter";
import urlFor from '../utils/ImgUrl';
import ModeCommentIcon from '@mui/icons-material/ModeComment';

export default function Post({postdata}) {
    return (
        <article>
            <Link to={`/bilde/${postdata._id}`}>
            <div className="meta">
                <img className="profilepic" src={postdata.profilepic ? urlFor(postdata.profilepic).width(50).format("webp").url() : `https://placehold.jp/24px/FFB001/4B2303/50x50.png?text=${postdata.author[0]}`} alt={postdata.author} />
                <span className="name"><strong>{postdata.author}</strong></span>
                <span className="date"><DateConverter date={postdata._createdAt} /></span>
                <span className="commentCount"><p><ModeCommentIcon fontSize="small" /> {
                    postdata.comments === 0 ? "0 kommentarer" : postdata.comments === 1 ? "1 kommentar" : `${postdata.comments} kommentarer`
                }</p></span>
            </div>
            
            <p>{postdata.text}</p>
            <img src={urlFor(postdata.image).width(600).format("webp").url()} alt={postdata.text} />
            </Link>
        </article>
    )
}
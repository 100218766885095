import { useEffect, useState } from "react"
import Loader from "../utils/Loader"
import { fetchAllPosts } from "../utils/sanityservices/postService"
import Post from "./Post"


export default function Frontpage() {
    //Prepare post state
    const [posts, setPosts] = useState(null)
    //Get all posts
    const getPosts = async () => {
        const data = await fetchAllPosts()
        setPosts(data)
    }

    useEffect(() => {
        getPosts()
    }, [])

    if(!posts) {
        return <Loader />
    }

    //RETURN
    return (
        <section className="flex-container">
        {posts?.map((p, i) => <Post key={i} postdata={p} />)}
        </section>
    )
}